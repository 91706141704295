@import "variables";
@import "nav";
@import "footer";
@import "talks";
@import "resources";
@import "sig";
@import "admin_nav";
@import "slider";
@import "carousel";
@import "media";
@import "drop";
@import "bootstrap";
@import "selectize/selectize";
@import "selectize/selectize.bootstrap3";
@import "font-awesome/font-awesome";
@import "lato-font";

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: $text-main;
}

.line-break {
    margin-bottom: 1em;
}

.display-table-cell {
    display: table-cell !important;
}

.display-none {
    display: none;
}

.icon-item-padding {
    padding-right: 5px
}

.line-break-half {
    margin-bottom: 0.5em !important;
}

.line-break-half-top {
    margin-top: 0.5em !important;
}

.line-break-dbl {
    margin-bottom: 2em;
}

.line-break-large {
    margin-bottom: 4em;
}

.line-break-top {
    margin-top: 1em;
}

.line-break-dbl-top {
    margin-top: 2em !important;
}

.display-block {
    display: block;
}

.padding {
    padding: 15px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.nopadding-left {
    padding-left : 0 !important;
}

.nosidepadding {
    padding-left: 0! important;
    padding-right: 0! important;
}

.noborder {
    border: 0 !important;
}

.noborderradius {
    border-radius: 0 !important;
}

.no-li-style {
    list-style-type: none !important;
    padding: 0 !important;
}

.jumbotron p {
    font-size:17px;
}

/* disable iOS to style text fileds and buttons */
input[type="submit"], input[type="button"],
input[type="text"], textarea, button {
    -webkit-appearance: none;
}

.axis-y {
    overflow: hidden;
    overflow-y: auto;
}

.nomargin {
    margin: 0 !important;
}

.nomargin-top {
    margin-top: 0 !important;
}

.nomargin-bottom {
    margin-bottom: 0 !important;
}

.nomargin-left {
    margin-left: 0 !important;
}

.nomargin-right {
    margin-right: 0 !important;
}

.strong {
    font-weight: bold !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.padding-left {
    padding-left: 1em;
}

.padding-right {
    padding-right: 1em;
}

.line-height-default {
    line-height: inherit !important;
}

#google_calendar > iframe {
    width: 100% !important;
    max-height: 100% !important;
}

#main-content {
    margin-top: 30px;
    margin-bottom: 50px;
}

.red-link, .red-link:hover, .red-link:active, .red-link:visited {
    color: red;
    text-decoration: none;
}

.badge-success {
    background-color: #5cb85c;
}

.signup {
    background-color: $light-grey;
    min-height: 300px;
    padding-top: 30px;
}

.input-icon-left .glyphicon {
  left: 0px;
}

.has-feedback.input-icon-left .form-control {
  padding-left: 43.5px;
}

.input-icon-left input {
  padding-left: 35px;
}

.checkboxText {
    padding-left: 8px;
}

.btn-signup:hover, .btn-signup:active {
    background-color: white !important;

}

.text-left {
    text-align: left !important;
}

.margin-right {
    margin-right: 1em;
}

.margin-right-dbl {
    margin-right: 2em;
}

.margin-left {
    margin-left: 1em;
}

.margin-left-dbl {
    margin-left: 2em;
}

.btn-primary2 {
    background: #337ab7 none repeat scroll 0 0;
    border-radius: 5px;
    color: #fff !important;
    font-weight: bold;
    display: inline-block;
    line-height: 1.6;
    margin-right: 20px;
    margin-top: 20px;
    outline: 0 none !important;
    padding: 15px;
    text-align: center;
}

.btn-primary2:hover {
    background-color: #275d8b;
    transition: background-color 500ms linear 0s;
    text-decoration: none !important;
}

.add-shadow {
    box-shadow: 0 12px 25px 0 rgba(0, 0, 0, 0.45) !important;
    outline: 0 none !important;
}

.full-width {
    width: 100% !important;
}

.glyphicon.glyphicon-large {
    font-size: 30px;
}

.square {
    width: 20%;
    height: 12vw;
    min-width: 130px;
    min-height: 130px;
}

.relative {
    position: relative;
}

.centered {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.bold {
    font-weight: bold !important;
}

.larger {
    font-size: larger !important;
}

.nopadding-top {
    padding-top: 0 !important;
}

.clear-both {
    clear: both;
}

.text-no-decoration {
    text-decoration: none !important;
}

.pull-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 48%;
    right: 0;
    margin: auto;
}

.thumb {
    max-width: 150px !important;
    max-height: 150px !important;
}

.sig-big-image {
    width: 100%;
}

.label-new {
    vertical-align: text-top !important;
}

.label-ukfn-blue {
    background-color: white !important;
    border: 1.5px $ukfn-darkblue solid !important;
    color: $ukfn-darkblue !important;
}

.label-ukfn-red {
    background-color: white !important;
    border: 1.5px $brand-danger solid !important;
    color: $brand-danger !important;
}

.label.highlight {
    background-color: $ukfn-darkblue !important;
    border: 1.5px $ukfn-darkblue solid !important;
    color: white !important;
}

.btn-ukfn {
    color: $ukfn-darkblue !important;
    border-color: $ukfn-darkblue !important;
    background-color: white !important;
}

.btn-ukfn:hover {
    background-color: $ukfn-darkblue !important;
    color: white !important;
}

.panel {
    border-bottom-width: 2px;
}

.well.responsive {
    overflow: auto;
}

.text-danger > .highlight {
    color: white !important;
    background-color: $brand-danger !important;
}

#well-col-1 {
    float: left;
    height: 100%;
    width: 33%;
    padding-left: 15px;
    padding-right: 15px;
}

#well-col-2 {
    float: left;
    height: 100%;
    width: 33%;
    padding-left: 15px;
    padding-right: 15px;
}

#well-col-3 {
    float: right;
    height: 100%;
    width: 33%;
    padding-left: 15px;
    padding-right: 15px;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

.loader {
    border: 16px solid $light-grey;
    border-top: 16px solid $ukfn-darkblue;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.hand-cursor {
  cursor: pointer;
}

.map-pointer {
  background: $ukfn-darkblue;
  border-radius: 0.7em;
  -moz-border-radius: 0.7em;
  -webkit-border-radius: 0.7em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.3em;
  margin-right: 5px;
  text-align: center;
  width: 1.4em;
  height: 1.4em;
  font-size: 13px;
  padding-right: 1px;
}

.map-pointer.red {
  background: $brand-danger;
}

.map-pointer:hover {
  background: $ukfn-lightblue;
}

.map-pointer.red:hover {
  background: lighten($brand-danger, 10%);
}

.map-pointer.selected {
  background: $brand-danger;
}

.map-pointer.red.selected {
  background: $ukfn-darkblue;
}

.map-pointer.small {
  border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  -webkit-border-radius: 0.5em;
  line-height: 1em;
  width: 1em;
  height: 1em;
}

.map-pointer.two-digits {
  border-radius: 0.9em;
  -moz-border-radius: 0.9em;
  -webkit-border-radius: 0.9em;
  line-height: 1.7em;
  width: 1.8em;
  height: 1.8em;
}

.map-pointer.three-digits {
  border-radius: 1em;
  -moz-border-radius: 1em;
  -webkit-border-radius: 1em;
  line-height: 1.9em;
  width: 2em;
  height: 2em;
  padding-right: 0px;
}

#cookie-bar, #cookie-bar-prompt-content {
  background: $ukfn-darkblue !important;
  font-weight: 400 !important;
  color: #FFF !important;
}

#cookie-bar-prompt-button {
  color: $light-grey !important;
}

#cookie-bar-button {
  background: $ukfn-lightblue !important;
  border: none !important;
  text-shadow: none !important;
  border-radius: 0 !important;
}

#cookie-bar-prompt-logo {
  display: none !important;
}

#cookie-bar-prompt-content a,
#cookie-bar-prompt-content span {
  color: $light-grey !important;
  text-decoration: underline !important;
}

#cookie-bar-prompt-content a:hover {
  color: #FFF !important;
}
