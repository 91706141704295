/* from https://www.w3schools.com/howto/howto_css_switch.asp */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  float: left;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.error {
  background-color: red;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $ukfn-darkblue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $ukfn-darkblue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-label {
  float: left;
  margin-top: 0.5em;
  font-size: 1.1em;
}
