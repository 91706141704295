/* Carousel base class */
.carousel {
  height: 500px;
  margin-bottom: 60px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
  margin-bottom: -1.5em;
  left: 0% !important;
  right: 0% !important;
  width: 100%;
}

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 500px;
  background-color: #FFF;
}
.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px;
}

@media (min-width: 768px) {
  .carousel-caption p {
    line-height: 1.4;
  }
}

