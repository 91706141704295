.selectize-control.plugin-drag_drop {
	&.multi > .selectize-input > div.ui-sortable-placeholder {
		visibility: visible !important;
		background: #f2f2f2 !important;
		background: rgba(black, 0.06) !important;
		border: 0 none !important;
		@include selectize-box-shadow (inset 0 0 12px 4px white);
	}
	.ui-sortable-placeholder::after {
		content: '!';
		visibility: hidden;
	}
	.ui-sortable-helper {
		@include selectize-box-shadow (0 2px 5px rgba(black, .2));
	}
}
