.talks-speake0 {
    font-weight: bold;
    font-style: italic;
}

a.list-group-item.talk:focus {
    background-color: transparent !important;
    outline: 0 !important;
}

#talks-filters {
    background-color: white !important;
    width: 100% !important;
    padding-top: 0px !important;
    padding-bottom: 35px !important;
    margin-top: 0px !important;
}

#talks-filters.affix {
    width: 30% !important;
    position: fixed !important;
}

.talk-series-filter > .selectize-control > .selectize-input {
    width: 75% !important;
    margin-top: 20px !important;
    margin-left: 60px !important;
    margin-bottom: -15px !important;
}

.talk-series-filter > .selectize-control > .selectize-dropdown {
    margin-left: 60px !important;
    margin-top: 15px !important;
    width: 75% !important;
}

#talks-filters ul {
    list-style: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

#talks-filters ul li {
    color: #000;
    display: block;
    position: relative;
    float: left;
    width: 100%;
}

#talks-filters ul li input[type=radio] {
    position: absolute;
    visibility: hidden;
}

#talks-filters ul li input[type=radio]:checked ~ .check {
    border: 4px solid $ukfn-darkblue;
}

#talks-filters ul li input[type=radio]:checked ~ .check::before{
    background: $ukfn-darkblue;
}

#talks-filters ul li input[type=radio]:checked ~ label{
    color: $ukfn-darkblue;
}

#talks-filters ul li label {
    display: block;
    position: relative;
    font-weight: 500;
    font-size: 1.1em;
    padding: 25px 25px 0px 60px;
    margin-top: 5px;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
}

#talks-filters ul li:hover label {
    color: $ukfn-lightblue;
}

#talks-filters ul li .check {
    box-sizing: initial;
    display: block;
    position: absolute;
    border: 4px solid $ukfn-darkblue;
    border-radius: 100%;
    height: 12px;
    width: 12px;
    top: 30px;
    left: 20px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

#talks-filters ul li:hover .check {
    border: 4px solid $ukfn-lightblue;
    cursor: pointer;
}

#talks-filters ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 2px;
    left: 2px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}
