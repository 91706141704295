@media only screen and (max-width: $screen-lg) and (min-width:1200px) {
    .hide-this {
        display: none;
    }

    .carousel-control.up {
        font-size: 30px !important;
    }
}

@media only screen and (min-width: $screen-sm) {
    .hide-desktop {
        display: none;
    }

    .carousel-control.up {
        font-size: 30px !important;
    }
}

@media only screen and (max-width: $screen-md) and (min-width:0px) {
    .hide-this {
        display: none;
    }

    .mobile-nopadding-from-md {
        padding: 0 !important;
    }

    #top-nav-bar > .nav.navbar-nav {
        margin-top: 17px !important;
    }

    .navbar-brand > img {
        height: 60px !important;
    }

    #talks-filters {
        position: relative !important;
        width: 98.5% !important;
        top: 0 !important;
        min-height: 150px !important;
    }

    #talks-filters.affix {
        width: 98.5% !important;
        position: relative !important;
    }

    #resources-filters {
        position: relative !important;
        width: 98.5% !important;
        top: 0 !important;
    }

    #resources-filters.affix {
        width: 98.5% !important;
        position: relative !important;
    }

    #talks-col {
        height: 58vh !important;
    }

    #resources-col {
        height: 58vh !important;
    }

    #well-col-1 {
        float: left !important;
        height: 100% !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #well-col-2 {
        float: left !important;
        height: 100% !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #well-col-3 {
        float: left !important;
        height: 100% !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .hide-mobile {
        display: none;
    }

    .no-axis-mobile {
        overflow-y: hidden !important;
    }

    .mapHeight {
        height: 100% !important;
    }

    .sig-extra-btn {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        margin-bottom: 15px !important;
    }

    #subscription-email {
        width: 80%;
    }

    #subscription-submit {
        width: 80%;
    }

    .footer-subscribe {
        margin-bottom: 150px;
    }
}

@media only screen and (max-width: $screen-xs) and (min-width:0px) {
    #sig-calendar-btn {
        margin-top: 5px;
    }

    .carousel-control.up {
        font-size: 20px !important;
    }

    h1 {
        font-size: 28px !important;
    }

    h2 {
        font-size: 24px !important;
    }

    h3 {
        font-size: 20px !important;
    }

    h4 {
        font-size: 16px !important;
    }

    h5 {
        font-size: 14px !important;
    }
}
