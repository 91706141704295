.site-footer {
  background: #111111;
  color: #BBBBBB;
  font-size: 14px;
}

.site-footer a {
  color: #03c4eb;
}

.site-footer .footer-connect {
  padding: 90px 0px;
  padding: 80px 0px;
  padding:  8rem 0px;
  text-align: center;
}

@media (max-width: 767px) {
  .site-footer .footer-connect .footer-subscribe {
    margin-bottom: 40px;
  }
}

.site-footer .footer-connect .follow-heading {
  color: #FFFFFF;
  margin-bottom: 20px;
  letter-spacing: 0.8px;
}

.site-footer .footer-connect .subs_input {
  background-color: rgba(255, 255, 255, 0.145);
  color: #cccccc;
  border-bottom: none;
  box-shadow: none;
  padding: 12px 15px 12px;
  border-radius: 2px;
  width: 60%;
  font-size: 13px;
}

.site-footer .footer-connect .subs_input:focus {
  border: none;
}

@media screen and (max-width: 1140px) {
  .site-footer .footer-connect .subs_input {
    width: 100%;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 940px) {
  .site-footer .footer-connect .subs_input {
    width: 70%;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 720px) {
  .site-footer .footer-connect .subs_input {
    width: 100%;
    margin-bottom: 4px;
  }
}

.site-footer .footer-connect .subs-button {
  margin: 0px;
  position: relative;
  top: 0px;
  padding: 16px 23px 16px;
}

@media screen and (max-width: 1140px) {
  .site-footer .footer-connect .subs-button {
    width: 100%;
  }
}

@media screen and (max-width: 940px) {
  .site-footer .footer-connect .subs-button {
    width: 70%;
  }
}

@media screen and (max-width: 720px) {
  .site-footer .footer-connect .subs-button {
    width: 100%;
  }
}

.site-footer .footer-connect input[type="text"]:focus {
  border: none !important;
}

.site-footer .footer-social {
  text-align: center;
}

.site-footer .footer-social a {
  font-size: 40px;
  margin: 0px 13px;
  color: $ukfn-darkblue;
}

.site-footer .footer-social a:hover {
  color: #03c4eb;
}

.site-footer .site-info {
  position: relative;
  text-align: center;
  padding: 35px 0px;
  background: #222222;
  font-weight: 500;
  color: #bbbbbb;
}

.site-info hr {
  border-top: 1px solid black;
}

.site-footer .site-info a {
  color: $ukfn-lightblue;
}

.site-footer .site-info a:hover {
  text-decoration: underline;
}

.site-footer .site-info .container {
  position: initial;
}

.site-footer .btt a {
  cursor: pointer;
  border-radius: 0px 20px 0 0;
  color: $ukfn-darkblue;
  background: #222222;
  display: block;
  font-size: 30px;
  height: 50px;
  line-height: 50px;
  margin: auto;
  position: absolute;
  right: 60px;
  top: -25px;
  transition: all 0.2s linear 0s;
  width: 50px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.site-footer .btt a:hover {
  color: $ukfn-lightblue;
}

.site-footer .btt a i {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  margin-left: 3px;
}

@media screen and (max-width: 940px) {
  .site-footer .btt a {
    right: 0;
    left: 0;
  }
}

#subscription-email {
  width: 103%;
  border-radius: 0px;
}

#subscription-submit {
  width: 103%;
  border-radius: 0px;
}
