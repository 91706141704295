//colours
$light-grey: #d9d9d9;
$ukfn-darkblue: #1B75BB;
$ukfn-lightblue: #2DA9E0;
$text-main: #333333;
$brand-primary: $ukfn-darkblue;
$brand-info: $ukfn-lightblue;
$brand-danger: #a94442;

// iconography
$icon-font-path: "../fonts/bootstrap/"; // path from css location (not sass)

// navbar
$navbar-default-bg: #FFFFFF;
$navbar-default-link-active-bg: transparent;

// media queries breakpoints
$screen-xs: 500px;
$screen-sm: 854px;
$screen-md: 992px;
$screen-lg: 1400px;

// carousel
$carousel-control-opacity: .3;
