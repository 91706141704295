.map-pointer {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    border: solid white 1px;
    background-image: radial-gradient(circle farthest-corner at 45px 45px , red, #a94442);
}

/* Important! without this margin the custom map pointer is offset */
.custom-marker.map-pointer {
    margin-top: 14px;
}

.mapHeight {
    height: 800px !important;
}

div.sig-map-image {
    padding: 5px 0px 10px 0px;
}

img.sig-map-image {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.carousel-control {
    opacity: 0.9 !important;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 1) !important;
}

.carousel-control.up {
    left: 43% !important;
    top: 5% !important;
    font-size: 20px !important;
}

.carousel-control.left {
    background-image: unset !important;
}

.carousel-control.right {
    background-image: unset !important;
}

.bs-callout-info {
    border-left-color: $ukfn-darkblue !important;
}

.bs-callout-danger {
    border-left-color: $brand-danger !important;
}

.bs-callout-info h4 {
    color: $ukfn-darkblue !important;
}

.bs-callout-danger h4 {
   color: $brand-danger !important;
}

.bs-callout {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #eee;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px 1px 1px 5px;
    margin: 20px 0;
    padding: 20px;
}

.panel-heading {
    padding: 15px 15px !important;
}

.panel-primary > .panel-heading > a {
    color: $ukfn-darkblue;
    text-decoration: none;
}

.panel-primary > .panel-heading {
    background-color: white !important;
}
